import React, { useState, useEffect } from 'react';
import './FeatureSection.css'; // External CSS file for styling

const imagesData = [
  { 
    src: 'images/image16.webp',
    description: 'Pictivatr'
  },
  { 
    src: 'images/image17.png',
    description: 'Pledge'
  },
  { 
    src: 'images/image18.jpg',
    description: 'Survey'
  },
  { 
    src: 'images/image4.png',
    description: 'Quiz'
  },
  { 
    src: 'images/image19.jpg',
    description: 'Video-Quiz'
  },
  { 
    src: 'images/image6.png',
    description: 'Gamification'
  },
  { 
    src: 'images/image7.jpg',
    description: 'Training'
  }
  // Add more images and descriptions as needed
];

const FeatureSection = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(prevIndex => (prevIndex + 1) % imagesData.length);
    }, 3000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="feature-section">
      <h2 className="section-title">CAMPAIGN IDEAS</h2>
      <div className="image-container">
        {imagesData.map((image, index) => (
          <div
            key={index}
            className={`image ${index === currentImageIndex ? 'active' : ''}`}
            style={{ backgroundImage: `url(${image.src})` }}
          >
            <p className="description">{image.description}</p>
          </div>
        ))}
      
      </div>
      
      <button className='centered-button'>Know More</button>
    </div>
  );
};

export default FeatureSection;
